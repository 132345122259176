/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

$(document).ready(async function () {

	// Responsive menu
	$('header').each(function () {
		var element = $(this),
			body = $(document.body),
			responsiveNav = $('.responsive-nav');

		var toggler = $('.toggler').click(function () {
			//body.toggleClass('open');
			responsiveNav.toggleClass('-collapsed');
			toggler.toggleClass('-closed');
		}).appendTo(element);
	});

	// Match height
	async function matchHeights() {
		await import('jquery-match-height');

		var element = $(this);
		$('.match-height').matchHeight({
			property: 'min-height'
		});
	};

	if ($('.match-height')[0]) {
		matchHeights();
	}

	// flipper on moblie
	$('.contact-box > .contact-box__item').each(function () {
		var element = $(this);

		element.on("touchend", function (e) {
			element.toggleClass('-open');
		});
	});

	// Dropdown 
	$(".dropdown").each(async function () {
		await import("../../node_modules/responsive-bp/src/js/responsive.core.js");
		await import("../../node_modules/responsive-bp/src/js/responsive.dropdown.js");
	});

	// Slideshow
	$('.slideshow').each(async function () {
		await import('slick-carousel');

		$(this).slick({
			arrows: false,
			dots: true,
			fade: true,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000
		});
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Parallax
	$('.parallaxer').each(async function () {
		await import('./jquery.parallaxer.js');

		$(this).parallaxer();

		// Fix voor not having the correct paramaeters in slidehsow
		setTimeout(function () {
			$(window).scrollTop($(window).scrollTop() + 1);
		}, 250);
		
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});
});